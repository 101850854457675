import React from "react"
import { graphql } from 'gatsby'
import { Fade } from "react-awesome-reveal";
import Banner from "../../components/schoolBanner"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import List from "../../components/listItems"
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const KaikoraiPage = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  const title = data.markdownRemark.frontmatter.title;
  const caption = data.markdownRemark.frontmatter.caption;
  const bannerImage = data.markdownRemark.frontmatter.banner_image.childImageSharp.gatsbyImageData;
  const listItems = data.markdownRemark.frontmatter.list_items;
  const body = data.markdownRemark.html;

  return (
    <Layout>
      <Seo title={title} description={caption} />
      <Banner image={bannerImage} />
      <section className="content school-content">
        <div className="row">
          <div className="col col-full">
            <Fade triggerOnce>
              <h1>{title}</h1>
              <Breadcrumb crumbs={crumbs} crumbSeparator=" / " />
            </Fade>
          </div>
          <div className="col col-two-third">
            <Fade triggerOnce>
              <p className="lead">{caption}</p>
              <div dangerouslySetInnerHTML={{ __html: body }} />
            </Fade>
          </div>
          <div className="col col-one-third">
            {/* <Fade triggerOnce>
              <List listItems={listItems} />
            </Fade> */}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`{
  markdownRemark(frontmatter: {template_key: {eq: "kaikorai"}}) {
    frontmatter {
      title
      banner_image {
        childImageSharp {
          gatsbyImageData(width: 900, quality: 100, layout: CONSTRAINED)
        }
      }
      caption
      list_items {
        title
        icon
      }
    }
    html
  }
}
`
export default KaikoraiPage
